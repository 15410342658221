<template>
  <div class="w-full">
    <template v-if="users.length">
      <table class="min-w-full">
        <thead>
          <tr>
            <th scope="col" class="px-6 py-3 text-left">Nombre de usuario</th>
            <th scope="col" class="px-6 py-3 text-left">Correo</th>
            <th scope="col" class="px-6 py-3 text-left">Estatus</th>
            <th scope="col" class="px-6 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i" :class="{ 'bg-primary-100': i % 2 === 0 }">
            <td scope="col" class="px-6 py-3 text-left">{{ user.name }}</td>
            <td scope="col" class="px-6 py-3 text-left">{{ user.email }}</td>
            <td scope="col" class="px-6 py-3 text-left">
              <BaseSwitch
                :value="user.active"
                @input="handleSwitchInput"
                @on="handleSwitchOn(user)"
                @off="handleSwitchOff(user)"
              />
            </td>
            <td scope="col" class="px-6 py-3 text-right space-x-4">
              <button
                type="button"
                class="rounded-full focus:outline-none focus:ring"
                @click="handlerEdit(user)"
              >
                <i class="tmlps-i-edit-circle text-3xl h-8 w-8"></i>
              </button>

              <button
                type="button"
                class="rounded-full focus:outline-none focus:ring"
                @click="handlerDelete(user)"
              >
                <i class="tmlps-i-delete-circle text-3xl h-8 w-8"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-start-4 col-span-6 mt-8 mx-auto">
          <img
            src="/img/search-no-results.png"
            alt="no-records"
            class="h-50 w-full object-cover md:w-80 mx-auto"
          />
          <h2 class="text-2xl text-center -mt-10">
            No hay usuarios registrados por el momento
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSwitch from '@/components/BaseSwitch';
export default {
  name: 'users-table',
  props: {
    users: { type: Array, default: null },
  },
  components: { BaseSwitch },
  methods: {
    handlerDelete(user) {
      this.$emit('delete-user', user);
    },
    handlerEdit(user) {
      this.$emit('edit-user', user);
    },
    async handleSwitchOn(user) {
      const form = new FormData();
      form.append('user_id', user.id);

      await this.$store.dispatch('tenant/enableUser', form);
      this.$emit('reload-records', true);
    },
    handleSwitchOff(user) {
      this.$emit('disable-user', user);
    },
    handleSwitchInput() {},
  },
};
</script>
