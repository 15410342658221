<template>
  <BaseModal :show="show" v-if="tenant" @close="handleCancelModal">
    <h3 slot="header" class="font-bold text-base">Agregar usuario</h3>

    <div class="space-y-6">
      <!-- User name -->
      <div>
        <BaseInput
          label="Nombre"
          placeholder="Nombre"
          :error="errors.first('name')"
          v-model="name"
        />
      </div>

      <!-- User email -->
      <div>
        <BaseInput
          label="Correo"
          placeholder="Correo"
          :error="errors.first('email')"
          v-model="email"
        />
      </div>

      <div class="flex ">
      <BaseSwitch :value="correoEnIngles" @on="correoEnIngles = 1" @off="correoEnIngles = 0" />
        <p style="padding-left: 10px;"> Enviar correo en ingles </p>
      </div>
    </div>

    <div slot="footer" class="mt-6 flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="handleCancelModal">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleAdd"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg>Agregar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
import BaseSwitch from '@/components/BaseSwitch.vue';
export default {
  name: 'users-create-modal',
  components: { BaseButton, BaseModal, BaseInput, BaseSwitch },
  props: {
    show: { type: Boolean, default: false },
    tenant: { type: Object, required: true },
  },
  data() {
    return {
      name: '',
      email: '',
      correoEnIngles: 0,
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    handleCancelModal() {
      this.clearForm();
      this.$emit('close');
    },
    async handleAdd() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('idioma', this.correoEnIngles ? 1 : 0);

      this.loading = true;
      this.errors.clear();

      try {
        const admin = await this.$store.dispatch('tenant/createUser', {
          tenantId: this.tenant.id,
          data: formData,
        });
        this.clearForm();
        this.$emit('close');
        this.$emit('created', admin);
        this.$store.dispatch('notification/addSuccess', 'El usuario se ha creado correctamente');
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.name = '';
      this.email = '';
      this.correoEnIngles=0;
      this.errors.clear();
    },
  },
};
</script>
