<template>
  <div>
    <div class="flex items-center justify-between border-b pb-4">
      <h3 class="font-bold">{{ tenant.users.length }} usuarios de {{ tenant.current.name }}</h3>
      <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
        Agregar
      </BaseButton>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap -m-4">
        <users-table
          :users="tenant.users"
          @disable-user="handlerDisableConfirmation"
          @edit-user="handlerEditModal"
          @delete-user="handlerDeleteConfirmation"
          @reload-records="reloadData"
        />
      </div>
    </div>

    <!-- CREATE USER -->
    <users-create-modal
      :show="showCreateModal"
      :tenant="tenant.current"
      @close="showCreateModal = false"
      @created="reloadData"
    />

    <!-- DISABLE USER -->
    <users-disable-confirmation-modal
      :show="showDisableConfirmation"
      :user="current"
      @close="showDisableConfirmation = false"
      @reload-records="reloadData"
    />

    <!-- EDIT USER -->
    <users-edit-modal
      :show="showEditModal"
      :user="current"
      @close="showEditModal = false"
      @reload-records="reloadData"
    />

    <!-- DELETE USER -->
    <users-delete-confirmation-modal
      :show="showDeleteModal"
      :user="current"
      @close="showDeleteModal = false"
      @reload-records="reloadData"
    />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import UsersTable from './components/Table';
import UsersEditModal from './components/EditModal';
import UsersCreateModal from './components/CreateModal';
import UsersDeleteConfirmationModal from './components/DeleteConfirmationModal';
import UsersDisableConfirmationModal from './components/DisableConfirmationModal';
export default {
  name: 'tenent-users',
  components: {
    BaseButton,
    UsersTable,
    UsersEditModal,
    UsersCreateModal,
    UsersDeleteConfirmationModal,
    UsersDisableConfirmationModal,
  },
  data() {
    return {
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      showDisableConfirmation: false,
      selected: null,
    };
  },
  computed: {
    ...mapState(['tenant']),
    current() {
      return this.selected;
    },
  },
  methods: {
    async reloadData(reload) {
      if (reload) await store.dispatch('tenant/fetchUsers', this.tenant.current.id);
    },
    async handlerEditModal(user) {
      this.selected = user;
      this.showEditModal = true;
    },
    handlerDeleteConfirmation(user) {
      this.selected = user;
      this.showDeleteModal = true;
    },
    handlerDisableConfirmation(user) {
      this.selected = user;
      this.showDisableConfirmation = true;
    },
  },
  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('tenant/fetchUsers', to.params.id);
  next();
}
</script>
